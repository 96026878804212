

export function FormatDate(date): string {

    if (date !== undefined && date !== "" && date !== null) {
        var myDate = new Date(date);
        var month = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ][myDate.getMonth()];
        var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
        return str;
    }
    return "";
}

export function GetDateFromString(dateString: string, format: string): Date {

    if (format == "hyphens-year-month-day") {
        let parts: string[] = dateString.split("-");
        return new Date(Number.parseInt(parts[0]), Number.parseInt(parts[1]) - 1, Number.parseInt(parts[2]), 12, 0, 0);
    }
    else if (format == "hyphens-year-month-day-timezone") {
        let trimmed = dateString.substr(0, dateString.indexOf("T"));
        return this.GetDateFromString(trimmed, "hyphens-year-month-day");
    }
    else return null;
}